import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Slider from 'react-slick'
import BannerSports from '../../../images/banner-sports.jpg'
import BannerCasino from '../../../images/banner-casino.jpg'
import BannerLottery from '../../../images/banner-lottery.jpg'

const ProductStyle = styled.section`
  background: #000;
  padding: 1rem;
`
const Badge = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(255, 190, 0, 0.7);
  font-weight: 700;
  padding: 0.5rem;
  color: #000;
  text-align: center;
`

const Product = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  }
  return (
    <ProductStyle>
      <div className="container mx-auto py-5">
        <div className="text-center">
          <Link to="/">
            ดูผลิตภัณฑ์ <span style={{ color: '#ffbe00' }}>/</span>{' '}
            โปรโมชั่นทั้งหมด <span style={{ color: '#ffbe00' }}>></span>
          </Link>
        </div>
        <Slider {...settings}>
          <div className="p-3">
            <Link to="/" className="block relative">
              <img src={BannerCasino} alt="banner" />
              <Badge className="text-lg lg:text-4xl">CASINO</Badge>
            </Link>
          </div>
          <div className="p-3">
            <Link to="/" className="block relative">
              <img src={BannerSports} alt="banner" />
              <Badge className="text-lg lg:text-4xl">SPORTS</Badge>
            </Link>
          </div>
          <div className="p-3">
            <Link to="/" className="block relative">
              <img src={BannerLottery} alt="banner" />
              <Badge className="text-lg lg:text-4xl">LOTTERY</Badge>
            </Link>
          </div>
        </Slider>
      </div>
    </ProductStyle>
  )
}

export default Product
