import React, { useState, useEffect } from 'react'
import Slider from 'react-slick'
import { Link } from 'gatsby'
import BannerDesktopFirst from '../../../images/banner-desktop-01.png'
import BannerDesktopSecond from '../../../images/banner-desktop-02.png'
import BannerDesktopThird from '../../../images/banner-desktop-03.jpg'
import BannerMobileFirst from '../../../images/banner-mb-01.jpg'
import BannerMobileSecond from '../../../images/banner-mb-02.jpg'
import BannerMobileThird from '../../../images/banner-mb-03.jpg'
import styled from 'styled-components'
import IconCasinoLive from '../../../images/icon-casino-live.png'
import IconCasino from '../../../images/icon-casino.png'
import IconSport from '../../../images/icon-sport.png'
import IconGame from '../../../images/icon-game.png'
import IconApp from '../../../images/icon-app.png'
import IconPromotion from '../../../images/icon-promotion.png'

const SlideDesktopStyle = styled.section`
  .slick-dots {
    bottom: -50px;
  }
  .slick-list {
    max-height: 685px;
  }
`

const SlideMobileStyle = styled.section`
  .slick-dots {
    bottom: 10px;
    li button:before {
      content: '';
      width: 12px;
      height: 12px;
      border: 2px solid #fff;
      opacity: 1;
      border-radius: 50%;
    }
    li.slick-active button:before {
      border: 2px solid #ffbe00;
    }
  }
`

const MainMenu = styled.nav`
  &.locked {
    position: fixed;
    top: 69px;
    left: 0;
    width: 100%;
    z-index: 12;
    background: #000;
    ul {
      justify-content: center;
      flex-wrap: wrap;
      li {
        width: 16.6666667%;
        border-bottom: 1px solid #333;
        :last-child {
          border-left: 1px solid #333;
          border-right: 1px solid #333;
        }
        :not(:last-child) {
          margin-right: 0;
          border-left: 1px solid #333;
        }
        a {
          padding: 0.75rem 0.5rem;
          img {
            display: none;
          }
          img + span {
            font-size: 0.65rem;
          }
        }
      }
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
    li {
      :not(:last-child) {
        margin-right: 0.5rem;
      }
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.5rem;
        transition: all 0.3s ease;
        :hover {
          background: rgba(255, 190, 0, 0.7);
        }
        img {
          margin-bottom: 0.25rem;
          max-width: 32px;
          max-height: 32px;
        }
        img + span {
          font-size: 0.75rem;
        }
      }
    }
  }
`

const Block = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background: rgba(0, 0, 0, 0.5);
  @media only screen (min-width: 1024px) {
    .container {
      max-width: 1000px;
    }
  }
`

const BlockMobile = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background: rgba(0, 0, 0, 0.5);
`

const Slide = () => {
  const [scrollPosition, setSrollPosition] = useState(0)
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  }

  const handleScroll = () => {
    const position = window.pageYOffset
    setSrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <>
      <SlideDesktopStyle>
        <div className="hidden lg:block">
          <Slider {...settings}>
            <div className="relative">
              <img
                src={BannerDesktopFirst}
                alt="พัฒนาระบบเกมได้มาตรฐานระดับโลก"
                className="w-full"
              />
              <Block>
                <div className="container mx-auto flex justify-end items-center px-5">
                  <div className="text-right">
                    <p className="text-xl font-light">
                      พัฒนาระบบเกมได้มาตรฐานระดับโลก
                    </p>
                    <h3 className="text-5xl">
                      ร่วมเป็นสมาชิกกับเราได้แล้ววันนี้
                    </h3>
                    <button
                      type="button"
                      className="bg-yellow-600 text-black px-5 py-2 rounded"
                    >
                      รายละเอียด
                    </button>
                  </div>
                </div>
              </Block>
            </div>
            <div className="relative">
              <img
                src={BannerDesktopSecond}
                alt="ระบบมีเกมให้เลือกมากมาย"
                className="w-full"
              />
              <Block>
                <div className="container mx-auto flex justify-end items-center px-5">
                  <div className="text-right">
                    <p className="text-xl font-light">
                      รวบรวมเกมยอดฮิตไว้เพื่อคุณ
                    </p>
                    <h3 className="text-5xl">ระบบมีเกมให้เลือกมากมาย</h3>
                    <button
                      type="button"
                      className="bg-yellow-600 text-black px-5 py-2 rounded"
                    >
                      รายละเอียด
                    </button>
                  </div>
                </div>
              </Block>
            </div>
            <div className="relative">
              <img
                src={BannerDesktopThird}
                alt="ได้รับความนิยมทั่วโลก"
                className="w-full"
              />
              <Block>
                <div className="container mx-auto flex justify-end items-center px-5">
                  <div className="text-right">
                    <p className="text-xl font-light">
                      มีผู้ใช้มากกว่า 16,000,000 คน
                    </p>
                    <h3 className="text-5xl">
                      ได้รับความนิยมทั่วโลกจากผู้ใช้กว่า 20 ประเทศ
                    </h3>
                    <button
                      type="button"
                      className="bg-yellow-600 text-black px-5 py-2 rounded"
                    >
                      รายละเอียด
                    </button>
                  </div>
                </div>
              </Block>
            </div>
          </Slider>
        </div>
      </SlideDesktopStyle>
      <SlideMobileStyle>
        <div className="lg:hidden">
          <Slider {...settings}>
            <div className="relative">
              <img
                src={BannerMobileFirst}
                alt="พัฒนาระบบเกมได้มาตรฐานระดับโลก"
                className="w-full"
              />
              <BlockMobile>
                <div className="container mx-auto flex justify-end items-center px-5">
                  <div className="text-right">
                    <p className="text-base font-light">
                      พัฒนาระบบเกมได้มาตรฐานระดับโลก
                    </p>
                    <h3 className="text-3xl">
                      ร่วมเป็นสมาชิกกับเรา
                      <br />
                      ได้แล้ววันนี้
                    </h3>
                    <button
                      type="button"
                      className="bg-yellow-600 text-black px-5 py-2 rounded"
                    >
                      รายละเอียด
                    </button>
                  </div>
                </div>
              </BlockMobile>
            </div>
            <div className="relative">
              <img
                src={BannerMobileSecond}
                alt="ระบบมีเกมให้เลือกมากมาย"
                className="w-full"
              />
              <BlockMobile>
                <div className="container mx-auto flex justify-end items-center px-5">
                  <div className="text-right">
                    <p className="text-base font-light">
                      รวบรวมเกมยอดฮิตไว้เพื่อคุณ
                    </p>
                    <h3 className="text-3xl">ระบบมีเกมให้เลือกมากมาย</h3>
                    <button
                      type="button"
                      className="bg-yellow-600 text-black px-5 py-2 rounded"
                    >
                      รายละเอียด
                    </button>
                  </div>
                </div>
              </BlockMobile>
            </div>
            <div className="relative">
              <img
                src={BannerMobileThird}
                alt="ได้รับความนิยมทั่วโลก"
                className="w-full"
              />
              <BlockMobile>
                <div className="container mx-auto flex justify-end items-center px-5">
                  <div className="text-right">
                    <p className="text-base font-light">
                      มีผู้ใช้มากกว่า 16,000,000 คน
                    </p>
                    <h3 className="text-3xl">
                      ได้รับความนิยมทั่วโลกจากผู้ใช้กว่า 20 ประเทศ
                    </h3>
                    <button
                      type="button"
                      className="bg-yellow-600 text-black px-5 py-2 rounded"
                    >
                      รายละเอียด
                    </button>
                  </div>
                </div>
              </BlockMobile>
            </div>
          </Slider>
          <MainMenu className={`${scrollPosition > 375 ? 'locked' : ''}`}>
            <ul>
              <li>
                <Link to="/">
                  <img src={IconCasinoLive} alt="คาสิโนสด" />
                  <span>คาสิโนสด</span>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src={IconCasino} alt="คาสิโน" />
                  <span>คาสิโน</span>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src={IconSport} alt="กีฬา" />
                  <span>กีฬา</span>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src={IconGame} alt="เกม" />
                  <span>เกม</span>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src={IconApp} alt="แอป" />
                  <span>แอป</span>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <img src={IconPromotion} alt="โปรโมชั่น" />
                  <span>โปรโมชั่น</span>
                </Link>
              </li>
            </ul>
          </MainMenu>
        </div>
      </SlideMobileStyle>
    </>
  )
}

export default Slide
