import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import BannerFirst from '../../../images/content-01.jpg'
import BannerSecond from '../../../images/content-02.jpg'
import BannerThird from '../../../images/content-03.jpg'
import BannerFourth from '../../../images/content-04.jpg'
import BannerFifth from '../../../images/content-05.jpg'
import Slider from 'react-slick'

const ContentStyle = styled.section`
  background: #242424;
  .mebet__slide--mobile {
    .p-3 {
      a {
        display: block;
        position: relative;
        .badge {
          position: absolute;
          bottom: 1rem;
          left: 0;
          text-align: left;
          background: rgba(255, 190, 0, 0.9);
          color: #000;
          padding: 0.5rem 1rem;
          font-size: 0.6875rem;
          border-radius: 0 1rem 0 0;
        }
      }
    }
    .slick-next:before {
      content: '>';
    }
    .slick-prev:before {
      content: '<';
    }
  }
`
const GridLayout = styled.div`
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
  div {
    position: relative;
    .badge {
      position: absolute;
      bottom: 2rem;
      left: 0;
      text-align: left;
      background: rgba(255, 190, 0, 0.7);
      color: #000;
      padding: 0.5rem 1rem;
      font-size: 0.88rem;
      border-radius: 0 1rem 0 0;
    }
  }
  div:first-child {
    grid-row: 1 / 3;
    grid-column: 1 / 3;
    display: flex;
    justify-content: center;
    align-items: center;
    .badge {
      padding: 1rem 2rem;
      font-size: 2rem;
      border-radius: 0 2rem 0 0;
    }
  }
`

const SampleNextArrow = props => {
  const { className, style, onClick } = props
  return <div className={className} style={{ ...style }} onClick={onClick} />
}

const SamplePrevArrow = props => {
  const { className, style, onClick } = props
  return <div className={className} style={{ ...style }} onClick={onClick} />
}

const HomeContent = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }
  return (
    <ContentStyle>
      <div className="container mx-auto text-center p-8 lg:p-3 lg:pt-24 lg:pb-16">
        <GridLayout className="hidden lg:grid">
          <div className="one">
            <Link to="/">
              <img src={BannerFirst} alt="banner" />
            </Link>
            <div className="badge">
              เปิดให้บริการผ่านทุกระบบ <br /> iOS และ Android
            </div>
          </div>
          <div>
            <Link to="/">
              <img src={BannerSecond} alt="banner" />
            </Link>
            <div className="badge">
              โปรโมชั่นแลกรับ 25% <br /> เมื่อสมัครสมาชิกครั้งแรก
            </div>
          </div>
          <div>
            <Link to="/">
              <img src={BannerThird} alt="banner" />
            </Link>
            <div className="badge">
              ทดลองเล่นฟรี ได้แล้ววันนี้ 24 ชม. <br /> ที่เกมเซนเตอร์
            </div>
          </div>
          <div>
            <Link to="/">
              <img src={BannerFourth} alt="banner" />
            </Link>
            <div className="badge">แอปพลิเคชั่น ไลฟ์คาสิโน</div>
          </div>
          <div>
            <Link to="/">
              <img src={BannerFifth} alt="banner" />
            </Link>
            <div className="badge">แอปพลิเคชั่น สปอร์ต</div>
          </div>
        </GridLayout>
        <div className="lg:hidden mebet__slide--mobile">
          <Slider {...settings}>
            <div className="p-3">
              <Link to="/">
                <img src={BannerFirst} alt="banner" />
                <div className="badge">
                  เปิดให้บริการผ่านทุกระบบ <br /> iOS และ Android
                </div>
              </Link>
            </div>
            <div className="p-3">
              <Link to="/">
                <img src={BannerSecond} alt="banner" />
                <div className="badge">
                  โปรโมชั่นแลกรับ 25% <br /> เมื่อสมัครสมาชิกครั้งแรก
                </div>
              </Link>
            </div>
            <div className="p-3">
              <Link to="/">
                <img src={BannerThird} alt="banner" />
                <div className="badge">แอปพลิเคชั่น ไลฟ์คาสิโน</div>
              </Link>
            </div>
            <div className="p-3">
              <Link to="/">
                <img src={BannerFourth} alt="banner" />
                <div className="badge">แอปพลิเคชั่น ไลฟ์คาสิโน</div>
              </Link>
            </div>
            <div className="p-3">
              <Link to="/">
                <img src={BannerFifth} alt="banner" />
                <div className="badge">แอปพลิเคชั่น สปอร์ต</div>
              </Link>
            </div>
          </Slider>
        </div>
      </div>
    </ContentStyle>
  )
}

export default HomeContent
