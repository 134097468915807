import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Slide from '../components/pages/home/slide'
import Content from '../components/pages/home/content'
import Product from '../components/pages/home/product'

const IndexPage = () => (
  <Layout>
    <SEO title="เว็บพนันออนไลน์ที่ดีที่สุด" />
    <Slide />
    <Content />
    <Product />
  </Layout>
)

export default IndexPage
